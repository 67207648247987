import React from 'react'
import { ChevronRight } from 'react-feather'
import { useLocation } from 'react-router-dom'

const RouteLabel = () => {

    const location = useLocation();
    var pathsArr = location.pathname.split('/');
    

    return (
        <div className='top-lbl-main'>

            <div className='route-data'>
                <i className='fas fa-home' />
                <p>Dashboard</p>
                <ChevronRight size={20} />
            </div>

            {pathsArr.map((val,i)=>{
                if(val=='')
                return null;
                return(
                    <div className='route-data'>
                    <i className='fas fa-desktop' />
                    <p>{val}</p>
                    {i<pathsArr.length-1 && <ChevronRight size={20} />}
                </div> 
                )
            })}
            {/* <div className='route-data'>
                <i className='fas fa-desktop' />
                <p>Partner</p>
                <ChevronRight size={20} />
            </div>


            <div className='route-data'>
                <i className='fas fa-desktop' />
                <p style={{color:"#aeaeae"}}>Manage Partner purchase orders</p>
            </div> */}

        </div>
    )
}

export default RouteLabel
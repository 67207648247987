import React from 'react'
import {
  RouterProvider, Route, Routes, useNavigate
} from "react-router-dom";
import { Login } from '../pages/Login';
import { Home } from '../pages/Home';
import { Employees } from '../pages/Employees';
import { CreateInvoice } from '../pages/CreateInvoice';
import { AcceptedInvoices } from '../pages/AcceptedInvoices';
import {CreatePartner} from '../pages/CreatePartner';
import {PartnersList} from '../pages/PartnersList';
import {ForgotPassword} from '../pages/ForgotPassword';
import {CreatePurchaseOrder} from '../pages/CreatePurchaseOrder';
import {EditPartner} from '../pages/EditPartner';
import {EditPurchaseOrder} from '../pages/EditPurchaseOrder';
import {InvoiceList} from '../pages/InvoiceList';
import { PendingInvoices } from '../pages/PendingInvoices';
import Dashboard from '../pages/Dashboard';


const RoutesMain = () => {
  return (
    <Routes>
      <Route path='auth/login/' element={<Login />} />
      <Route path='auth/forgot-password/' element={<ForgotPassword />} />
      <Route path='/' element={<Home />} />
      <Route path='/employees/' element={<Employees />} />
      <Route path='/invoices/create-invoice/' element={<CreateInvoice />} />
      <Route path='/invoices/accepted-invoice/' element={<AcceptedInvoices />} />
      <Route path='/invoices/all-invoices/' element={<InvoiceList />} />

      <Route path='/partners/create-partner/' element={<CreatePartner />} />
      <Route path='/partners/edit-partner/' element={<EditPartner />} />

      <Route path='/partners/partners-list/' element={<PartnersList />} />
      <Route path='/partners/create-purchase-order/' element={<CreatePurchaseOrder />} />
      <Route path='/partners/edit-purchase-order/' element={<EditPurchaseOrder />} />
      <Route path='/invoices/pending-invoice/' element={<PendingInvoices />} />

      <Route path='/dashboard/' element={<Dashboard />} />





    </Routes>


  )
}

export default RoutesMain
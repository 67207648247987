import React from 'react'

const NotFound = () => {
  return (
    <div className='loading-page-main'>
      <h1>No Data Found...</h1>
    </div>
  )
}

export default NotFound
import logo from './logo.svg';
import './App.css';
import Layout1 from './layout/Layout1';
import { useLocation, useNavigate } from 'react-router-dom';
import { LayoutMapping } from './LayoutMapping';
import BlankLayout from './layout/BlankLayout';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { BASE_LOGIN_URL, logout } from './utils';
import { useDispatch, useSelector } from 'react-redux';
import { assignUserDetails } from './redux/actions/users'



function App() {

  var location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoggedInChecked, setIsLoggedInChecked] = useState(false)
  const currentLoginData = useSelector(state => state.userReducer);
  console.log(currentLoginData)

  const navigate = useNavigate();
  const dispatch = useDispatch();


  async function verifyLogin() {
    var login = localStorage.getItem("loginDetails");
    if (login) {

      login = JSON.parse(login)
      console.log(login)

      try {

        var res = await axios.post(BASE_LOGIN_URL + "/login", {
          ...login
        })
        if (res.status == 200) {
          if (!location.pathname.includes("login")) {
            //alert("loggedin")
            setIsLoggedInChecked(true);

            return;
          }
          else {
            navigate("/")
          }

        }
      }
      catch (e) {
        if (!location.pathname.includes("login"))
          navigate("/auth/login")
      }

    }
    else
      if (!location.pathname.includes("login"))
        navigate("/auth/login")

    setIsLoggedInChecked(true);

  }









  useEffect(() => {
    window.onstorage = () => {

      var login = localStorage.getItem("loginDetails");
      if (login) {

        login = JSON.parse(login)
      } else {
        login = null
      }

      if ((currentLoginData != null && login != null) && JSON.stringify(currentLoginData) != JSON.stringify(login)) {
        localStorage.setItem("loginDetails", null);
        logout();
        window.location.reload();

      }
    };
    return () => { }
  }, [currentLoginData])


  useEffect(() => {
    var login = localStorage.getItem("loginDetails");
    if (login) {
      login = JSON.parse(login)
    } else {
      login = null
    }
    verifyLogin();
    setIsLoggedIn(true)
    dispatch(assignUserDetails(login))

  }, [])





  // alert(location.pathname)
  if (isLoggedIn && isLoggedInChecked)
    return (
      <div className="App">
        {LayoutMapping[location.pathname] == "blank" ? <BlankLayout /> : <Layout1 />}
      </div>
    );
  return <></>
}

export default App;

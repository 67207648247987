import React from 'react'
import { ClipLoader } from 'react-spinners'

const LoadingComponent = () => {
  return (
    <div className='loading-page-main'>
        <ClipLoader 
          color={"#ff2404"}
          loading={true}
        //   cssOverride={override}
          size={60}
          aria-label="Loading Spinner"
          data-testid="loader"
          />
          <h1>Loading...</h1>
    </div>
  )
}

export default LoadingComponent
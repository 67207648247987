import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import { BASE_URL } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import RouteLabel from '../components/RouteLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditPartner = () => {
    const ref = useRef(null);
    
    const notifySuccess = () => toast.success("Edited Successfully");
    const notifyErr = () => toast.error("Failed to edit partner!");
    const navigate = useNavigate();

    var location = useLocation(), userData = {
        name: '',
        email: '',
        contactPerson: '',
        btwNumber: '',
        phone: '',
        streetName:'abcd colonyu'
    };
    if (location.state && location.state.userData) {
        userData = location.state.userData;
    }
    console.log(userData)
    const formik = useFormik({
        initialValues: {
            name: userData.name,
            email: userData.email,
            contactPerson: userData.contactPerson,
            btwNumber: userData.btwNumber,
            phone: userData.phone,
            streetName:"",

        },
        validate: (values) => {


            const errors = {};

            console.log(values, values.name.length)
            if (!values.name || values.name.length <= 0) {
                console.log(errors)
                errors.name = 'This field is Required';
            }
          

            if (!values.phone) {
                errors.phone = 'This field is Required';
            }
            else if (!values.phone.match(/^\d{10}$/)) {
                errors.phone = 'Invalid Phone Number';

            }
     

            if (!values.contactPerson) {
                errors.contactPerson = 'This field is Required';
            }
       

            if (!values.btwNumber) {
                errors.btwNumber = 'This field is Required';
            }
         

            if (!values.email) {
                errors.email = ' This field is Required ';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
         

            return errors




        },
        onSubmit: values => {
            editPartner()

        },
    });


    useEffect(()=>{
        if(userData.name=='')
        navigate('/partners/partners-list')
    },[])

    async function editPartner() {

        try {
            if (ref.current)
                ref.current.continuousStart();
            let data = {};
            if (formik)
                data = { ...formik.values };
            data.phoneNumber = data.phone;

            console.log(data)
            var res = await axios.put(BASE_URL + '/partners/'+userData.partnerId, { ...data }, {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                }
            });
            if (res.status == 200) {
               notifySuccess();
               navigate('/partners/partners-list')
            }
        } catch (e) {
          notifyErr();
        }
        if (ref.current)
            ref.current.complete()
    }


    console.log(formik)

    return (
        <div>

            <div className='create-partner-form-main'>

                <LoadingBar color='#3072bb' ref={ref} height={7} />

                <div className='create-partner-form'>
                    <div className='form-heading-label'>
                        <h1><i className="fas fa-edit" style={{ marginRight: "20px", }}></i>Edit Partner</h1>
                        <Link to="/partners/partners-list"> <Button className="page-btn">View Partners <i className='fas fa-eye'></i></Button> </Link>

                    </div>
                    <div className='form-body-row'>
                        {/* <div className='img-col'>
                 <i className="fas fa-user-plus" style={{ marginRight: "0px", }}/>
                 <h4>Create new Partner</h4>
                 </div> */}
                        <PartnerCreateForm formik={formik} />
                    </div>
                    <div className='form-bottom'>
                        <Button className="page-btn" onClick={formik.handleSubmit}>Edir Partner Details <i className='fas fa-edit'></i></Button>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { EditPartner }
import axios from "axios";

export const BASE_URL="/im-api/invoice-management"
export const BASE_LOGIN_URL="/im-api/invoice-management"
export const testingUrl="http://localhost:3000"
//"http://localhost:8000" //"https://ras360.nl/im-api/invoice-management"


export const getUserData = ()=>{
    var login = localStorage.getItem("loginDetails");
    if(login){
      login = JSON.parse(login);
    }
    return login;
    
}



export const logout = async ()=>{

  var res = await axios.delete(BASE_URL+"/logout");
  if(res.status==200){
     return true;
  }
  return false;
}




import React, { useState } from 'react'
import { Col, Form, Row, Label, FormGroup, Input, Button } from 'reactstrap'
import LoginImg from '../assets/Login.svg'
import './page.css'
import { Link } from 'react-router-dom'
import logo from '../assets/callisto-logo.svg'
import { ArrowDownRight, ArrowRight } from 'react-feather'
import { BASE_URL } from '../utils'
import axios from 'axios'
const ForgotPassword = () => {

   const [email,setEmail] = useState("");

   async function resetPassword(){
    if(!validateEmail()){
      alert("Please enter correct email") 
      return ;
    }
    try{
      var res = await axios.post(BASE_URL+"/resetPassword",{userName:email});
      if(res.status==200){
        alert("Success")
      }
    }
    catch(e){
        alert("Failed")
    }
   }




    function validateEmail(){

    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
        return false
      }
     return true

    }



    return (

        <Row className='forgot-password-page-parent' >

          <div className='forgot-password-form-container'  >
            
            <h1>Reset Password</h1>
  

              <Form className='form'>
              <FormGroup className="mb-5">
            <h2 for="exampleEmail" className='logo-heading' >
      
      <img className='logo' src={logo} width="120" height="29" style={{scale:"1.8"}} />
      </h2>
      </FormGroup>
                <FormGroup className="mb-5">
                  <Label className='label-heading' for="exampleEmail" >Enter your email for verification.</Label>
                  <Input type="email" name="email" id="exampleEmail" placeholder="Enter your E-mail" onChange={(e)=>{setEmail(e.target.value)}} />
                </FormGroup>
                <Button className='page-btn' onClick={resetPassword}>Send Request <i className="fas fa-paper-plane" style={{marginLeft:"13px"}}></i></Button>
                 <Link  className="login-navigation-link" to="/auth/login"><ArrowRight size={10} /> Go back to Login Page</Link>
              </Form>
    
            </div>
    
    
          {/* </Col> */}
    
        </Row>
    
      )
}

export  {ForgotPassword}
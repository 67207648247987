// export const incNumber = () => {
//     return {
//         type: 'INCREMENT',
//         payload: 2
//     }
// };

export const toggleSidebarGlobal = (val) => {
    return {
        type: 'HANDLE_SIDEBAR_COLLAPSE',
        val: val
    }
}


export const toggleSidebarMobile = (val) => {
    return {
        type: 'HANDLE_SIDEBAR_COLLAPSE_MOBILE',
        val: val
    }
}


import React, {useState} from 'react'
import { ChevronDown, Circle, Disc } from 'react-feather'
import { Link } from 'react-router-dom'
import { Card, CardBody, Collapse, UncontrolledCollapse } from 'reactstrap'

const DropdownLink = ({link,icon,title,sublinks,featherIcon, isSidebarCollapsed,id}) => {

   
    const [openSubNavigation,setSubNavigation] = useState(false);
    
    if(isSidebarCollapsed && openSubNavigation){
        
        setSubNavigation(false);
    
    }



    return (
    
        <div style={openSubNavigation?{background:"#fff"}:{}}>
            <Link>
                <div className='side-nav-parent' id={id} onClick={()=>setSubNavigation(!openSubNavigation)}>
                    <div className='side-nav'>
                    {featherIcon}
                    {isSidebarCollapsed?<></>:<span className='sidebar-nav-title-main'>{title}</span>}
                    </div>
                    <div>
                        <div className='preview' style={!isSidebarCollapsed?{}:{display:"block"}}>
                            {featherIcon}
                        </div>
                        <ChevronDown size={20} style={{ marginRight: "10px" }} />
                    </div>
                </div>
             
            </Link>
            <Collapse id={"collapse-"+id} isOpen={openSubNavigation}>
                    <div style={openSubNavigation?{    background: "rgb(244 244 244 / 66%)",borderTop: "1px solid #ededed",borderBottom: "1px solid #ededed",boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",paddingLeft: "20px"}:{}}>
                    {sublinks.map(val=>{
                        return  <Link to={val.path}>
                        <div className='side-nav-parent sub-navigation' id="toggler">
                        <div className='side-nav sub-side-nav' >
                            <Disc  size={39} style={{ "color": "#484848", paddingRight: "20px" }} />
                            <span className='sub-link'>{val.title}</span>
                        </div>
                        <div>
                       
                        </div>
                    </div>
                    </Link>

                    })}
                    
                  
           
                    </div>
                </Collapse>




        </div>
    )
}

export default DropdownLink
const initialState = true;

const sidebarCollapseReducer = (state = initialState, action) => {
    switch (action.type) {
        case "HANDLE_SIDEBAR_COLLAPSE": return action.val;
        default: return state;
    }
}

const initialState2 = false;

const sidebarCollapseReducerMobile = (state = initialState2, action) => {
    switch (action.type) {
        case "HANDLE_SIDEBAR_COLLAPSE_MOBILE": return action.val;
        default: return state;
    }
}

export default sidebarCollapseReducer;
export {sidebarCollapseReducerMobile}
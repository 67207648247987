import React, { useReducer } from 'react'
import { User } from 'react-feather'
import { UncontrolledDropdown,DropdownToggle,DropdownMenu,DropdownItem } from 'reactstrap'
import './css/components.css'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toggleSidebarMobile } from '../redux/actions/sidebar'
import { getUserData, logout } from '../utils'
const Navbar = () => {

  const dispatch = useDispatch();
  let isSidebarCollapsed = useSelector(state=>state.sidebarCollapseReducerMobile)
  const userData = getUserData();

  return (
    <div className='navbar'>
        <div>
          <i className='fas fa-bars menu-mobile' style={{color:"#fff",fontSize:"22px",marginLeft:"10px"}} onClick={()=>{dispatch(toggleSidebarMobile(!isSidebarCollapsed))}}></i>
        </div>
        <div>
        <UncontrolledDropdown>
      <DropdownToggle className='profile-dropdown' caret>
      <User className='nav-profile-sect' size={40} />
      <span className='ml-3' style={{color:"#fff",fontSize:"14px"}}><b>{userData? (userData.role=="ADMIN"?" Hello Admin":"Partner User"):""}</b> <i className="fas fa-caret-down"></i></span><br/>
      <span className='ml-3 role-lbl' style={{color:"#fff"}}><b>{userData && userData.role}</b> </span>

      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem style={{    borderBottom:"1px solid #ededed",paddingLeft: "10px"}} header><span style={{color:"#0064ff"}}>Email: </span>{userData && userData.userName}</DropdownItem>
        
        <a href='/auth/login' onClick={()=>{localStorage.setItem("loginDetails",null);logout();}}><DropdownItem ><i className="fas fa-sign-out-alt" style={{marginRight:"10px"}}></i>Logout</DropdownItem></a>
     
      </DropdownMenu>
    </UncontrolledDropdown>
        </div>
    </div>
    )
}

export default Navbar
const initialState = null;

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case "HANDLE_LOGGED_USER_DATA": return action.val;
        default: return state;
    }
}



export default userReducer;

import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Input } from 'reactstrap'
import { Tooltip } from 'reactstrap';

import './page.css'
import Table from '../components/Table';
import SearchBar from '../components/SearchBar';
import { Plus } from 'react-feather';
import axios from 'axios';
import { BASE_URL, getUserData, logout } from '../utils';
import RouteLabel from '../components/RouteLabel';
import { Link, useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import LoadingComponent from '../components/LoadingComponent';
import ErrorComponent from '../components/ErrorComponent';
import NotFound from '../components/NotFound';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';




export const Home = () => {

  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [err, setErr] = useState(false);
  const [retry, setRetry] = useState(false)

  const navigate = useNavigate();
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);

  const userData = getUserData();
  
  const notifyDel = () => toast.success("Deleted Successfully");
  const notifyErr = () => toast.error("Failed to delete Purchase Order!");
  
  /*{
    "orders": [
        {
            "purchaseOrderNumber": "2a674f74-7074-409e-9e7c-4c95598e51fe",
            "consultantName": "Consultant 1",
            "startDate": "2023-07-01",
            "endDate": "2023-12-31",
            "location": "Netherlands",
            "rate": 500,
            "orderType": "DAILY",
            "currency": "EUR",
            "btwNumber": 21,
            "paymentTerm": "MONTHLY"
        }
    ]
}*/

  async function getPurchaseOrders() {
    try {
      if (ref.current)
        ref.current.continuousStart()
      setLoading(true)

      var res = await axios.get(BASE_URL + '/purchaseorders');
      if (res.status == 200) {
        setPurchaseOrders(res.data.orders);
        setErr(false)
      }
    }
    catch (e) {
      setErr(true)
      logout();
      localStorage.setItem("loginDetails",null)
      window.location.reload()
            
    }
    if (ref.current)
      ref.current.complete()
    setLoading(false)

  }


  useEffect(() => {
    if (err)
      setErr(!err)
    getPurchaseOrders();
  }, [retry])

    console.log(purchaseOrders);
  const columns = useMemo(
    () => [
      {
        Header: "Partner",
        accessor: "partner.name",

      },
      {
        Header: "Consultant",
        accessor: "consultantName",

      },
      {
        Header: "Start Date",
        accessor: "startDate"
      },
      {
        Header: "End Date",
        accessor: "endDate"
      },
      {
        Header: "Location",
        accessor: "location"
      },
      {
        Header: "Rate",
        accessor: "rate"
      },
      {
        Header: "Type",
        accessor: "orderType"
      },
      {
        Header: "Currency",
        accessor: "currency"
      },
      {
        Header: "Vat Rate",
        accessor: "btwPercentage"
      },
      {
        Header: "Payment term",
        accessor: "paymentTerm"
      },



      {
        Header: "Action ",
        Cell: (row) => {
          console.log(row)
          return (
            <div className='tbl-action-col'>


              {userData && userData.role=="ADMIN"?<RenderBtn id={"edit"+row.row.id} tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-purchase-order", { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
              {userData && userData.role!="ADMIN"?<RenderBtn id={"add"+row.row.id} tooltip="Create Invoice" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-plus" ></i>} onClick={() => { navigate("/invoices/create-invoice", { state: { orderData: { ...row.row.original } } }); }}></RenderBtn>:null}
              {/* <RenderBtn id={"down"+row.row.id} tooltip="Download" className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} ></RenderBtn> */}
              {userData && userData.role=="ADMIN"?<RenderBtn id={"del"+row.row.id} tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { deletePurchaseOrder(row.row.original) /*navigate("/session-timed-out");*/ }}></RenderBtn>:null}

            </div>
          );
        }
      }
    ]
    ,
    []
  );


  async function deletePurchaseOrder(purchaseOrder){
    if (ref.current)
      ref.current.continuousStart()
      try{
         var res = await axios.delete(BASE_URL+"/purchaseorders/"+purchaseOrder.purchaseOrderNumber);
         if(res.status==200 || res.status==204){
          notifyDel();
          getPurchaseOrders();
         }
      }
      catch(e){
          notifyErr();
      }
      if (ref.current)
        ref.current.complete()
  }

  function RenderBtn({ className, onClick, icon, tooltip, id, children }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
      <>

        <Button id={id} className={className} color="btn btn-primary" onClick={onClick}>{icon}</Button>
        <Tooltip
                    isOpen={tooltipOpen}
                    toggle={toggle}
                    placement="right" target={id} /*toggle={this.toggle}*/>
                    {tooltip}
                </Tooltip>
           </>
    )
  }


  return (
    <div>
      <LoadingBar color='#3072bb' ref={ref} height={7} />
      {purchaseOrders.length > 0 ? <div>      <RouteLabel />
        <h2 className='page-title'>Manage partner purchase orders  </h2>
        <Table data={purchaseOrders} columns={columns} title={"Partner Purchase Orders"} />
      </div> : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : loading ? <LoadingComponent /> : <NotFound />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>

  )
}

export default Home
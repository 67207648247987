import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, Input, Tooltip } from 'reactstrap'
import './page.css'
import Table from '../components/Table';
import SearchBar from '../components/SearchBar';
import { File, Plus } from 'react-feather';
import axios from 'axios';
import { BASE_URL, getUserData, downloadFile } from '../utils';
import RouteLabel from '../components/RouteLabel';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';
import CommentsModal from '../components/CommentsModal';
import ErrorComponent from '../components/ErrorComponent';
import LoadingComponent from '../components/LoadingComponent';
import NotFound from '../components/NotFound';
import TableTabs from '../components/TableTabs';

export const AcceptedInvoices = ({ invoiceData, setInvoiceTbl, invoiceTbl }) => {

  const navigate = useNavigate();

  const [acceptedInvoices, setAcceptedInvoices] = useState([]);
  const [currentData, setCurrentData] = useState(null)
  const [openModal, setOpenModal] = useState(false);
  const [retry, setRetry] = useState(false)
  const [err, setErr] = useState(false);
  const userData = getUserData();
  const [loading, setLoading] = useState(false)
  const ref = useRef(null);
  const [currentRemarks, setCurrentRemarks] = useState([]);

  async function getEmployees() {
    setLoading(true);

    try {
      var res = await axios.get(BASE_URL + '/invoices');
      if (res.status == 200) {
        res.data.invoices = res.data.invoices.filter(val => val.status == "ACCEPTED")
        setAcceptedInvoices(res.data.invoices);
        setErr(false)
      }
    }
    catch (e) {
      setErr(true)
    }
    setLoading(false);

  }


  // useEffect(() => {
  //   getEmployees();
  // }, [retry])

  useEffect(() => {
    var invoices = invoiceData.filter(val => val.status == "ACCEPTED")
    setAcceptedInvoices(invoices);
  }, [])

  const columns = useMemo(
    () => [

      // {
      //   Header: "P.No",
      //   accessor: "btwNumber"

      // },
      // {
      //   Header: "Name",
      //   accessor: "name",

      // },
      {
        Header: "Invoice No",
        accessor: "invoiceNumber"
      },
      {
        Header: "Invoice Date",
        accessor: "invoiceDate"
      },
      // {
      //   Header: "Start Date",
      //   accessor: "startDate"
      // },
      // {
      //   Header: "End Date",
      //   accessor: "endDate"
      // },
      // {
      //   Header: "Rate",
      //   accessor: "rate"
      // },
      // {
      //   Header: "Quantity",
      //   accessor: "quantity"
      // },
      {
        Header: "Amount",
        accessor: "amount"
      },
      // {
      //   Header: "VAT",
      //   accessor: "vat"
      // },
      {
        Header: "Total Amount",
        accessor: "totalAmount"
      },
      {
        Header: "Timesheets",
        accessor: "timesheet",
        Cell: (row) => <div onClick={() => downloadFile(row.row.original.timesheet)} ><div className="tbl-action-btn-dwn" ><i className='bi bi-arrow-down-square-fill' style={{ marginRight: "10px" }} />{row.row.original.timesheet} </div></div>
      },

      {
        Header: "Documents",
        accessor: "document",
        Cell: (row) => <div onClick={() => downloadFile(row.row.original.document)}><div className="tbl-action-btn-dwn" ><i className='bi bi-arrow-down-square-fill' style={{ marginRight: "10px" }} />{row.row.original.document}</div></div>

      },
      {
        Header: "Email",
        accessor: "email",
        Cell: (row) => <div onClick={() => downloadFile(row.row.original.email, ref)}><div className="tbl-action-btn-dwn" ><i className='bi bi-arrow-down-square-fill' style={{ marginRight: "10px" }} />{row.row.original.email}</div></div>

      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {

          return (
            <div style={{ display: "flex" }} className='tbl-action-col status-drpdwn' id={'status-drpdwn-'+row.row.id}>

              {(row.row.original.status != "COMPLETED" && userData && userData.role == "ADMIN") ? <Select className='form-drpdwn1'

                options={[
                  {
                    label: "CREATED",
                    value: "CREATED"

                  }
                  ,
                  {
                    label: "COMPLETED",
                    value: "COMPLETED"
                  },
                  {
                    label: "PENDING",
                    value: "PENDING"
                  },
                  {
                    label: "VOID",
                    value: "VOID"
                  },
                  {
                    label: "ACCEPTED",
                    value: "ACCEPTED"
                  }
                ]}
                onChange={(opt) => {
                  changeStatus(opt.value, row.row.original)
                }}
                // value={{
                //     label:row.row.original.status
                // }}
                placeholder={row.row.original.status}

              /> : <div>{row.row.original.status}</div>}
            </div>
          );
        }
      },

      {
        Header: "Action ",
        Cell: (row) => {

          return (
            <div style={{ display: "flex" }}>

              {/* <RenderBtn id="edit" tooltip={"Edit"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-edit" ></i>} onClick={() => { navigate("/partners/edit-partner", { state: { userData: { ...row.row.original } } }); }}></RenderBtn> */}
              <RenderBtn id="del" tooltip={"Delete"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-trash" ></i>} onClick={() => { /*navigate("/session-timed-out");*/ }}></RenderBtn>
              {row.row.original.status != "COMPLETED" ? <RenderBtn id={"add-rem" + row.row.id} tooltip={"Add Remarks"} className='tbl-action-btn add-remarks-btn' color="btn btn-primary" icon={<i className="fas fa-comments" ></i>} onClick={() => { setCurrentData(row.row.original);/*navigate("/session-timed-out");*/setOpenModal(true); getRemarks(row.row.original); }}></RenderBtn> : null}
              <RenderBtn id="download" tooltip={"Download"} className='tbl-action-btn' color="btn btn-primary" icon={<i className="fas fa-arrow-down" ></i>} onClick={() => { downloadInvoice(row.row.original) }}></RenderBtn>

            </div>
          );
        }
      }

    ]
    ,
    []
  );

  function RenderBtn({ className, onClick, icon, tooltip, id, children }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
      <>

        <Button id={id} className={className} color="btn btn-primary" onClick={onClick}>{icon}</Button>
        <Tooltip
          isOpen={tooltipOpen}
          toggle={toggle}
          placement="right" target={id} /*toggle={this.toggle}*/>
          {tooltip}
        </Tooltip>
      </>
    )
  }

  async function downloadFile(data, ref2) {
    if (ref.current)
      ref.current.continuousStart()

    axios({
      url: BASE_URL + '/invoices/files/' + data + '.pdf',
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {

      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', data + '_file.pdf');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }).catch(
      function (error) {
        alert("Failed while Downloading file...")
        return
      }
    )
    if (ref.current)
      ref.current.complete()
  }

  async function changeStatus(status, invData) {
    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.put(BASE_URL + '/invoices/' + invData.id, { status }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }, params: {
          "status": status
        }
      });
      console.log("API RES:", res)
      alert("Updated")
      //if (res.status == 201 ) {

      //setInvoiceList("Remarks added");
      //}
    }
    catch (e) {
      //setErr(true);
      alert("Failed while Changing status...")
    }
    if (ref.current)
      ref.current.complete()
  }







  async function downloadInvoice(invData) {
    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.get(BASE_URL + '/invoices/files/' + invData.id, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });
      console.log("API RES:", res)
      if (res.status == 200) {

        //setInvoiceList("Remarks added"); 
      }
    }
    catch (e) {
      //setErr(true);
      alert("Failed while Download...")
    }
    if (ref.current)
      ref.current.complete()
  }



  async function addRemarks(msg) {
    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.post(BASE_URL + '/invoices/' + currentData.id + "/remarks", { remarks: msg }, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });
      console.log("API RES:", res)
      if (res.status == 200) {

        // alert("Remarks added");
        getRemarks(currentData);


      }
    }
    catch (e) {
      //setErr(true);
      alert("Failed while adding Remarks...")
    }
    if (ref.current)
      ref.current.complete()
  }

  async function getRemarks(remarkData) {

    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.get(BASE_URL + '/invoices/' + remarkData.id + "/remarks", {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });
      console.log("API RES:", res)
      if (res.status == 200) {
        setCurrentRemarks(res.data)
        // alert("Remarks added");
      }
    }
    catch (e) {
      //setErr(true);
      alert("Failed while adding Remarks...")
    }
    if (ref.current)
      ref.current.complete()
  }


  return (
    <div>
      {/* <LoadingBar color='#3072bb' ref={ref} height={7} /> */}
      {/* {acceptedInvoices.length > 0 ?  */}
      <div>

        <TableTabs tabsData={[
          {
            key: "all",
            label: "All Invoices",
            icon: () => <i class="fas fa-list-ul"></i>
          },
          {
            key: "pending",
            label: "Pending Invoices",
            icon: () => <i class="fas fa-file-invoice"></i>

          },
          {
            key: "accepted",
            label: "Accepted Invoices",
            icon: () => <i class="fas fa-file"></i>

          }
        ]} setTab={setInvoiceTbl} currentTab={invoiceTbl}
        />
        <h2 className='page-title'>Manage "Accepted" partner invoices
        {/* <div className='add-invoice-link' to='/invoices/create-invoice/' ><Button className='page-btn' onClick={() => setInvoiceTbl("all")}  >All Invoices <i className="bi bi-file-earmark-fill"></i></Button><Button className='page-btn' onClick={() => setInvoiceTbl("pending")}  >Pending Invoices <i className="bi bi-file-earmark-arrow-up-fill"></i></Button><Button className='page-btn' onClick={() => setInvoiceTbl("accepted")}  >Accepted Invoices <i className="bi bi-file-check-fill"></i></Button></div> */}
        </h2>
        <Table data={acceptedInvoices} columns={columns} title={"Accepted Invoice List"} />
      </div>
      {/* : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : loading ? <LoadingComponent /> : <NotFound />*/}
      <CommentsModal isOpen={openModal} setIsOpen={setOpenModal} addRemarks={addRemarks} currentRemarks={currentRemarks} />
    </div>
  )
}


import React from 'react'
import { ChevronRight } from 'react-feather'
import { useLocation } from 'react-router-dom'

const TableTabs = ({tabsData,setTab,currentTab}) => {
  return (
    <div className='top-lbl-main tabs-parent-sect'>

    {/* <div className='route-data'>
        <i className='fas fa-home' />
        <p>Dashboard</p>
        <ChevronRight size={20} />
    </div> */}

    {tabsData.map((val,i)=>{
        if(val=='')
        return null;
        return(
            <div className={'route-data tab-navigation '+(currentTab==val.key?'tab-activ':'')} onClick={()=>setTab(val.key)}>
            {/* <i className='fas fa-desktop' /> */}
            {val.icon()}
            <p>{val.label}</p>
            {/* {i<pathsArr.length-1 && <ChevronRight size={20} />} */}
        </div> 
        )
    })}
    {/* <div className='route-data'>
        <i className='fas fa-desktop' />
        <p>Partner</p>
        <ChevronRight size={20} />
    </div>


    <div className='route-data'>
        <i className='fas fa-desktop' />
        <p style={{color:"#aeaeae"}}>Manage Partner purchase orders</p>
    </div> */}

</div>
  )
}

export default TableTabs
import React, { useRef } from 'react'
import InvoiceForm from '../components/InvoiceForm'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import { BASE_URL } from '../utils';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';

export const CreateInvoice = () => {


  const navigate = useNavigate();
  var location = useLocation();
  var orderData = {
    invoiceNumber: '',
    invoiceDate: '',
    startDate: '',
    endDate: '',
    quantity: '',
    rate: '',
    rateType: '',
    amount: '',
    btwAmount: '',
    totalAmount: '',
    status:''


  };
  if (location.state && location.state.orderData)
    orderData = location.state.orderData


  

  var ref = useRef(null);
  var currentDate = new Date();
  var { startDate, endDate } = orderData;

  const date1 = new Date(startDate);
  const date2 = new Date(endDate);

  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  var amount = 0;
  console.log(orderData)
  const formik = useFormik({
    initialValues: {
      "purchaseOrderId":orderData.purchaseOrderNumber,
      invoiceNumber: '',
      invoiceDate: currentDate.getFullYear() + "-" + (currentDate.getMonth()<9?("0"+(currentDate.getMonth()+1)):(currentDate.getMonth() + 1)) + "-" + (currentDate.getDate()<10?("0"+currentDate.getDate()):(currentDate.getDate())),
      startDate: new Date(orderData.startDate),
      endDate: new Date(orderData.endDate),
      quantity: 0,
      rate: orderData.rate,
      rateType: orderData.orderType,
      amount: amount,
      "btwNumber":orderData.btwNumber,
      btwAmount: (orderData.btwPercentage * amount) / 100 ||0,
      totalAmount: amount + ((orderData.btwPercentage * amount) / 100)||0,
      status:"CREATED",
      document:undefined,
      timesheet:undefined,
      approvalEmail:undefined


    },
    validate: (values) => {

      const errors = {};

      if (!values.invoiceNumber) {
        errors.invoiceNumber = 'This field is Required';
      }

      if (!values.invoiceDate) {
        errors.invoiceDate = 'This field is Required';
      }

      if (!values.startDate) {
        errors.startDate = 'This field is Required';
      }

      if (!values.endDate) {
        errors.endDate = 'This field is Required';
      }

      if (!values.quantity) {
        errors.quantity = 'This field is Required';
      }

      if (!values.rate) {
        errors.rate = 'This field is Required';
      }

      if (!values.rateType) {
        errors.rateTypes = 'This field is Required';
      }

      if (!values.amount) {
        errors.amount = 'This field is Required';
      }

      if (!values.btwAmount && isNaN(values.btwAmount)) {
        errors.btwAmount = 'This field is Required';
      }

      if (!values.totalAmount) {
        errors.totalAmount = 'This field is Required';
      }

      return errors;


    },
    onSubmit: values => {
      try{

        const url = window.URL.createObjectURL(
          new Blob([values.document]),
        );
        console.log(url)
      const reader = new FileReader();
      // const reader2 = new FileReader();
      // const reader3 = new FileReader();
      //console.log(values)
      var docs={};
       reader.readAsDataURL(values.document);
      reader.onloadend = () =>{ 
         formik.setFieldValue("document",reader.result)
         
         docs.document = reader.result
         reader.readAsDataURL(values.timesheet);


         reader.onloadend = () =>{ 
            
             formik.setFieldValue("timesheet",reader.result)
             docs.timesheet = reader.result

             reader.readAsDataURL(values.approvalEmail);
             reader.onloadend = () =>{ 
                formik.setFieldValue("approvalEmail",reader.result)
                // reader.readAsDataURL(values.timesheets);
                docs.approvalEmail = reader.result

                addInvoice(docs);
     
           };
              

      };

      };
    }catch(err){
      alert("Error occur while loading files. Please try again")
    }

    },
  });


  const addInvoice = async (docs) => {

    try {
      console.log(formik.values,docs)
      if (ref.current)
        ref.current.continuousStart();

      var data = { ...formik.values,...docs };
      data.startDate = data.startDate.getFullYear() + "-" + (data.startDate.getMonth()<9?("0"+(data.startDate.getMonth() + 1)):(data.startDate.getMonth() + 1)) + "-" + (data.startDate.getDate()<10?"0"+data.startDate.getDate():data.startDate.getDate());
      data.endDate = data.endDate.getFullYear() + "-" + (data.endDate.getMonth()<9?("0"+(data.endDate.getMonth() + 1)):(data.endDate.getMonth() + 1)) + "-" + (data.endDate.getDate()<10?"0"+data.endDate.getDate():data.endDate.getDate());
              
      var res = await axios.post(BASE_URL + "/invoices", data, {});
      if (res.status == 201) {
        alert("Invoice Created Sucessfully");
        navigate("/invoices/all-invoices")
      }
    } catch (e) {
      alert("Failed")
    }
    if (ref.current)
      ref.current.complete();
  }


  function setRateDetails(qty) {

    formik.setFieldValue("quantity", qty);
    var amount = orderData.orderType == "HOURLY" ? orderData.rate * qty * 8 : orderData.rate * qty
    formik.setFieldValue("amount", amount)
    formik.setFieldValue("btwAmount", (orderData.btwPercentage * amount) / 100 || 0)
    formik.setFieldValue("totalAmount", amount + ((orderData.btwPercentage * amount) / 100 || 0))

  }

  console.log(formik)



  return (
    <div className='create-invoice-form-sect'>
      <LoadingBar color='#3072bb' ref={ref} height={7} />
      <h2 className='page-title'>Create Partner Invoice</h2>
      
      <InvoiceForm  orderData={orderData} formik={formik} setRateDetails={setRateDetails} />

    </div>
  )
}


import axios from 'axios';
import { useFormik } from 'formik';
import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import DatePicker from "react-datepicker";
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

const CreatePurchaseForm = ({formik}) => {
 
if(formik)
   console.log(formik.errors)

  if(formik)
  return (
    <div className='form-body'>
    <div className='inp-rows'>
        <div>
            <Label>Consultant Name:</Label><br />
            <div className="cp-row">
                <i className='fas fa-user'></i>
                <Input type="text" placeholder="Consultant Name.." name='consultantName' value={formik.values.consultantName} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/>
            </div>

            {formik.errors.consultantName && formik.touched.consultantName ? <span className='form-err-text'>{formik.errors.consultantName}</span>:null}

        </div>
        {/* <div>
            <Label>Partner ID:</Label><br />
            <div className="cp-row">
                <i className='fas fa-user'></i>
                <Input type="text" placeholder="Partner ID.." name='partnerId' value={formik.values.partnerId} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/>

            </div>
            {formik.errors.partnerId && formik.touched.partnerId ? <span className='form-err-text'>{formik.errors.partnerId}</span>:null}

        </div> */}
          <div>
            <Label>Payment Term:</Label><br />
            <div className="cp-row">
                <i className='far fa-credit-card'></i>
                {/* <Input type="email" placeholder="Payment Term.." name='paymentTerm' value={formik.values.paymentTerm} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/> */}
                <Select className='form-drpdwn' options={[
                 {
                   label:"DAILY",
                   value:"DAILY"
                 },
                 {
                   label:"MONTHLY",
                   value:"MONTHLY"
                 },
               

                ]}
                onBlur={formik.handleBlur}
                onChange={(opt)=>{
                    formik.setFieldValue("paymentTerm",opt.value)
                }}
                placeholder={formik.values.paymentTerm} />

            </div>
            {formik.errors.paymentTerm && formik.touched.paymentTerm ? <span className='form-err-text'>{formik.errors.paymentTerm}</span>:null}

        </div>

    </div>
    <div className='inp-rows cols-3'>


    <div>
            <Label>Location:</Label><br />
            <div className="cp-row">
                <i className='fas fa-location-arrow'></i>
                <Input type="email" placeholder="Location.." name='location' value={formik.values.location} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/>

            </div>
            {formik.errors.location && formik.touched.location ? <span className='form-err-text'>{formik.errors.location}</span>:null}

        </div>






        <div>
            <Label>Start Date:</Label><br />
            <div className="cp-row">
                <i className='fas fa-calendar-week'></i>
                <DatePicker className='date-input' onBlur={formik.handleBlur} selected={formik.values.startDate} onChange={(date) =>{console.log(date); formik.setFieldValue("startDate",date)}} dateFormat="yyyy-MM-dd" />

            </div>
            {formik.errors.startDate && formik.touched.startDate ? <span className='form-err-text'>{formik.errors.startDate}</span>:null}

        </div>
        <div>
            <Label>End Date:</Label><br />
            <div className="cp-row">
                <i className='fas fa-calendar-week'></i>
                <DatePicker className='date-input' onBlur={formik.handleBlur} selected={formik.values.endDate} onChange={(date) => formik.setFieldValue("endDate",date)} dateFormat="yyyy-MM-dd"/>

            </div>
            {formik.errors.endDate && formik.touched.endDate ? <span className='form-err-text'>{formik.errors.endDate}</span>:null}

        </div>

        
        </div>


        <div className='inp-rows cols-33'>
        <div>
            <Label>Rate Type:</Label><br />
            <div className="cp-row">
                <i className='fas fa-users'></i>
                {/* <Input type="text" placeholder="Order Type.." name='orderType' value={formik.values.orderType} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/> */}
                <Select className='form-drpdwn' options={[
                 {
                   label:"HOURLY",
                   value:"HOURLY"
                 },
                 {
                   label:"DAILY",
                   value:"DAILY"
                 },
                 
                

                ]}
                onBlur={formik.handleBlur}
                onChange={(opt)=>{
                    formik.setFieldValue("type",opt.value)
                }}
                
                placeholder={formik.values.type==''?"Rate Type...":formik.values.type} />
            </div>
            {formik.errors.type && formik.touched.type ? <span className='form-err-text'>{formik.errors.orderTypes}</span>:null}

        </div>
        <div>
            <Label>Rate:</Label><br />
            <div className="cp-row">
                <i className='fas fa-euro-sign'></i>
                <Input type="text" placeholder="Rate.." name='rate' value={formik.values.rate} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/>

            </div>
            {formik.errors.rate && formik.touched.rate ? <span className='form-err-text'>{formik.errors.rate}</span>:null}

        </div>

   
     </div>
        {/* <div>
    <Label>Name:</Label><br/>
    <Input type="text" placeholder="Name.." />
</div> */}
    



    <div className='inp-rows cols-3'>
    <div>
            <Label>Currency:</Label><br />
            <div className="cp-row">
                <i className='fas fa-dollar-sign'></i>
                <Input type="email" placeholder="Currency.." name='currency' value={formik.values.currency} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/>

            </div>
            {formik.errors.currency && formik.touched.currency ? <span className='form-err-text'>{formik.errors.currency}</span>:null}

        </div>
        <div>
            <Label>VAT Percentage:</Label><br />
            <div className="cp-row">
                <i className='fas fa-file'></i>
                <Input type="text" placeholder="VAT Percentage.." name='btwPercentage' value={formik.values.btwPercentage} onChange={formik.handleChange} onBlur={formik.handleBlur} /><br/>

            </div>
            {formik.errors.btwPercentage && formik.touched.btwPercentage ? <span className='form-err-text'>{formik.errors.btwPercentage}</span>:null}

        </div>
      
    </div>

</div>
  )
  else return null;
}

export default CreatePurchaseForm
import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, Input } from 'reactstrap'
import './page.css'
import Table from '../components/Table';
// import { data } from '../dummy-db/employees'
import SearchBar from '../components/SearchBar';
import { Filter, Plus } from 'react-feather';
import axios from 'axios';
import { BASE_URL } from '../utils';

export const Employees = () => {

  const [employees, setEmployees] = useState([]);

  async function getEmployees() {
    try {
      var res = await axios.get(BASE_URL + '/data');
      if (res.status == 200) {
        setEmployees(res.data);
      }
    }
    catch (e) {

    }
  }


  useEffect(() => {
    getEmployees();
  }, [])


  const columns = useMemo(
    () => [
      {

        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Email",
        accessor: "email",

      },
      {
        Header: "Project Code",
        accessor: "Project_Code",

      },
      {
        Header: "DOB",
        accessor: "DOB"
      },
      {
        Header: "Phone",
        accessor: "phone"
      },
      {
        Header: "Link",
        Cell: (row) => {

          return (
            <>

              <i class="fas fa-link" style={{ margin: "0px 10px", cursor: "pointer", color: "#333", fontSize: "20px" }}></i>
              <i class="fas fa-pencil-alt" style={{ margin: "0px 10px", cursor: "pointer", color: "#333", fontSize: "20px" }}></i>
              <i class="far fa-chart-bar" style={{ margin: "0px 10px", cursor: "pointer", color: "#333", fontSize: "20px" }}></i>
            </>
          );
        }
      }
    ]
    ,
    []
  );








  return (
    <div>
      <div className='employee-page-header' style={{ width: "95%" }}>

        <h2>Employees</h2>

        <Button color="" className='custom-button'>Add new <Plus size={20} /></Button>

      </div>

      <Card style={{ overflowX: "scroll" }}>
        <div className='employee-page-header' style={{ paddingBottom: "30px", borderBottom: "none", color: "grey", paddingTop: "20px", paddingLeft: "20px", fontFamily: "monospace", marginBottom: "0" }}>


        </div>
        {console.log(employees)}
        <Table columns={columns} data={employees} />
      </Card>

    </div>
  )
}


import { combineReducers } from "redux";
import sidebarCollapseReducer,{sidebarCollapseReducerMobile} from './sidebar'
import userReducer from "./users";
const reducers = combineReducers(
    {
    //   myNumber:changeTheNumber
    sidebarCollapseReducer,
    sidebarCollapseReducerMobile,
    userReducer
    }
);

export default reducers;
import React from 'react'
import syncErrLogo from '../assets/SyncErr.svg'
import syncLogo from '../assets/Sync.svg'
import { Bluetooth } from 'react-feather'

const ErrorComponent = ({retry,setRetry}) => {
  return (
    <div className='loading-page-main'>
        <img src={syncErrLogo} width="80" height="80" style={{color:"gray"}} />
        <h1>Unable to load data!</h1>
        <div className='retry-btn'  onClick={()=>setRetry(!retry)}>

        <img src={syncLogo} width="20" height="20" style={{color:"gray",marginRight:"4px"}} />
        <span>Retry</span>
        </div>
        
    </div>
  )
}

export default ErrorComponent
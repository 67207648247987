import axios from 'axios';
import { useFormik } from 'formik';
import React, { useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
import { BASE_URL } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import PartnerCreateForm from '../components/PartnerCreateForm';
import { object, string, number, date, InferType,phone } from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CreatePartner = () => {

    const notifySuccess = () => toast.success("Partner Created Successfully");
    const notifyErr = () => toast.error("Failed to create partner!");
    const navigate = useNavigate();


    // let partnerSchema = object({
    //     name: string().required(),
    //     btwNumber: number().required().positive().integer(),
    //     email: string().email(),
    //     phone: string().phone().required(),
    //     contactPerson:string().required()
    //   });
    


    //console.log(orderData)
    
    const ref = useRef(null);

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            contactPerson: '',
            btwNumber:'',
            phone:'',
            country:'',
            streetName:'',
            houseNumber:'',
            postcode:'',
            alias:'',
        },
        validate:(values)=>{
            
            const errors = {};
             
            console.log(values,values.name.length)
            if (!values.name || values.name.length<=0) {
                console.log(errors)
                errors.name = 'This field is Required';
            }
    

            if (!values.phone  ) {
                errors.phone = 'This field is Required';
            }

            else if(!(/\d{10}/i).test(values.phone)){
                // alert("not")
                console.log((/\d{10}/i).test(values.phone))
                errors.phone = 'Invalid Phone Number';

            }
         

            if (!values.contactPerson) {
                errors.contactPerson = 'This field is Required';
            }
    
            if (!values.btwNumber) {
                errors.btwNumber = 'This field is Required';
            }
            // if (!values.country) {
            //     errors.country = 'This field is Required';
            // }
            // if (!values.postcode) {
            //     errors.postcode = 'This field is Required';
            // }
            // if (!values.number) {
            //     errors.number = 'This field is Required';
            // }
            // if (!values.streetName) {
            //     errors.streetName = 'This field is Required';
            // }
            // if (!values.alias) {
            //     errors.alias = 'This field is Required';
            // }

            if (!values.email) {
              errors.email = ' This field is Required ';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
              errors.email = 'Invalid email address';
            }
   
           return errors;


        },
        onSubmit:  (values) => {
           // alert();
            addPartner();
        },
    });


    async function addPartner(){

        try{
        if(ref.current)
        ref.current.continuousStart();
        let data = {};
        if(formik)
        data = {...formik.values};
        data.phoneNumber = data.phone;
        // data.btwNumber = parseInt(data.btwNumber)

        console.log(data)
      //  alert("")
        var res = await axios.post(BASE_URL + '/partners',{...data},{
            headers:{
                "Content-Type":"application/json",
                "Access-Control-Allow-Origin": "*"
            }
        });
        if(res.status==201){
            // alert("Created Successfully")
            navigate("/partners/partners-list")

        }
        }catch(e){
          notifyErr();
        }
        if(ref.current)
        ref.current.complete()
   }


   
    return (
        <div className='create-partner-form-main'>
                  <LoadingBar color='#3072bb' ref={ref} height={7} />

            <div className='create-partner-form'>
                <div className='form-heading-label'>
                    <h1><i className="fas fa-user-plus" style={{ marginRight: "20px", }}></i>Create Partner</h1>
                    <Link to="/partners/partners-list"> <Button className="page-btn">View Partners <i className='fas fa-eye'></i></Button> </Link>

                </div>
                <div className='form-body-row'>
          
          
                  <PartnerCreateForm formik={formik}/>
                </div>
                <div className='form-bottom'>
                <Button className="page-btn" onClick={formik.handleSubmit}>Add New Partner</Button>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export { CreatePartner}
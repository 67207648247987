import React from 'react'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS } from "chart.js/auto";

const LineChart = ({chartData}) => {
  return (
    <Line data={chartData} style={{}} options={{maintainAspectRatio: false
        ,responsive:true,scales: {
          xAxes: [{
              categoryPercentage: 1,
              barPercentage: 1
          }]} }}  />
  )
}

export default LineChart
import React from 'react'
import RoutesMain from '../routes'

const BlankLayout = () => {
  return (
    <div>
        <RoutesMain/>
    </div>
  )
}

export default BlankLayout
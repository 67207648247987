import axios from 'axios';
import { useFormik } from 'formik';
import React, { useRef } from 'react'
import { Link } from 'react-router-dom';
import { Button, Input, Label } from 'reactstrap'
const PartnerCreateForm = ({formik}) => {
 
  if(formik)
  return (
    <div className='form-body'>
    <h3 className='form-grp-lbl'>Personal Details:</h3>
    <div className='inp-rows cols-3'>
        <div>
            <Label>Name:</Label><br />
            <div className="cp-name">
                <i className='fas fa-user'></i>
                <Input type="text" placeholder="Name.." name='name' value={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>
            </div>
            {formik.errors.name && formik.touched.name ? <span className='form-err-text'>{formik.errors.name}</span>:null}

        </div>
        <div>
            <Label>Phone:</Label><br />
            <div className="cp-phone">
                <i className='fas fa-phone'></i>
                <Input type="text" placeholder="Phone.." name='phone' value={formik.values.phone} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.phone && formik.touched.phone ? <span className='form-err-text'>{formik.errors.phone}</span>:null}

        </div>


        <div>
            <Label>Alias:</Label><br />
            <div className="cp-email">
            <i className="fas fa-id-badge"></i>
                <Input type="text" placeholder="Alias.." name='alias' value={formik.values.alias} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.alias && formik.touched.alias ? <span className='form-err-text'>{formik.errors.alias}</span>:null}

        </div>

    </div>
    <div className='inp-rows cols-33'>
        <div>
            <Label>Contact Person:</Label><br />
            <div className="cp-contact">
                <i className='fas fa-users'></i>
                <Input type="text" placeholder="Contact Person.." name='contactPerson' value={formik.values.contactPerson} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.contactPerson && formik.touched.contactPerson ? <span className='form-err-text'>{formik.errors.contactPerson}</span>:null}

        </div>
        <div>
            <Label>BTW Number:</Label><br />
            <div className="cp-id">
                <i className='fas fa-file'></i>
                <Input type="text" placeholder="btwNumber.." name='btwNumber' value={formik.values.btwNumber} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.btwNumber && formik.touched.btwNumber ? <span className='form-err-text'>{formik.errors.btwNumber}</span>:null}

        </div>
     
        <div>
            <Label>Email:</Label><br />
            <div className="cp-email">
                <i className='fas fa-envelope'></i>
                <Input type="email" placeholder="Email.." name='email' value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.email && formik.touched.email ? <span className='form-err-text'>{formik.errors.email}</span>:null}

        </div>
        {/* <div>
    <Label>Name:</Label><br/>
    <Input type="text" placeholder="Name.." />
</div> */}
    


    </div>


    <h3 className='form-grp-lbl' style={{marginTop:"10%", marginBottom:"30px"}}>Address Details:</h3>


    <div className='inp-rows '>






        <div>
            <Label>House Number:</Label><br />
            <div className="cp-email">
            <i className='fas fa-location-arrow'></i>
                <Input type="text" placeholder="House Number.." name='houseNumber' value={formik.values.houseNumber} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.houseNumber && formik.touched.houseNumber ? <span className='form-err-text'>{formik.errors.houseNumber}</span>:null}

        </div>

        <div>
            <Label>Postal Code:</Label><br />
            <div className="cp-email">
            <i className="fas fa-map-pin"></i>
                <Input type="text" placeholder="Postal Code.." name='postalCode' value={formik.values.postalCode} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.postalCode && formik.touched.postalCode ? <span className='form-err-text'>{formik.errors.postalCode}</span>:null}

        </div>
    </div>


    <div className='inp-rows'>
    <div>
            <Label>Streetname:</Label><br />
            <div className="cp-email">
               
                <i className="fas fa-road"></i>
                <Input type="text" placeholder="streetname.." name='streetName' value={formik.values.streetName} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.streetName && formik.touched.streetName ? <span className='form-err-text'>{formik.errors.streetName}</span>:null}

        </div>

 




        <div>
            <Label>Country:</Label><br />
            <div className="cp-email">
            <i className='fas fa-location-arrow'></i>
                <Input type="text" placeholder="Country.." name='country' value={formik.values.country} onChange={formik.handleChange} onBlur={formik.handleBlur}/><br/>

            </div>
            {formik.errors.country && formik.touched.country ? <span className='form-err-text'>{formik.errors.country}</span>:null}

        </div>
    </div>



    {/* <div className='inp-rows'>


  
    </div> */}

</div>
  )
  else return null;
}

export default PartnerCreateForm
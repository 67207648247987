import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { BASE_URL, logout } from '../utils';
import RouteLabel from '../components/RouteLabel';
import ErrorComponent from '../components/ErrorComponent';
import LoadingComponent from '../components/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import LoadingBar from 'react-top-loading-bar';
import BarChart from '../components/charts/BarChart';
import PieChart from '../components/charts/PieChart'
import LineChart from '../components/charts/LineChart'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ChartLayoutComp } from '../components/charts/ChartLayout';
import Table from '../components/Table';
import Select from 'react-select';

const Dashboard = () => {

    const ref = useRef(null);
    const [invoiceList, setInvoiceList] = useState([]);
    const navigate = useNavigate();
    const [partnersList, setPartnersList] = useState([]);
    const [err, setErr] = useState(false);
    const [retry, setRetry] = useState(false)
    const [overviewData, setOverviewData] = useState({})
    const [grpData, setGrpData] = useState([])
    const [years, setYears] = useState([]);
    const [curYear, setCurYear] = useState("2024")
    const [curMonth, setCurMonth] = useState("01")




    var monthLabels = [
        { label: "January", value: "01" }, { label: "Febuary", value: "02" },
        { label: "March", value: "03" }, { label: "April", value: "04" },
        { label: "May", value: "05" }, { label: "June", value: "06" },
        { label: "July", value: "07" }, { label: "August", value: "08" },
        { label: "September", value: "09" }, { label: "October", value: "10" },
        { label: "November", value: "11" }, { label: "December", value: "12" },
    ];
    var yearLabels = [];



    function getDates(year,m) {
        if (m==undefined)
        m="01"
        // alert(year)
        var date = new Date(year +"-"+m+ "-01"), y = date.getFullYear(), m = date.getMonth();
        var firstDay = new Date(y, m, 1).getDate();
        var lastDay = new Date(y, m + 1, 0).getDate();
        m++;
        if (m < 10)
            m = "0" + m;
        if (firstDay < 10)
            firstDay = "0" + firstDay
        if (lastDay < 10)
            lastDay = "0" + lastDay

        return { fd: (y + '-' + m + '-' + firstDay), ld: (y + '-' + m + '-' + lastDay) }
        // console.log(y+'-'+m+'-'+firstDay)
        // console.log(y+'-'+m+'-'+lastDay)
    }

    async function getDashboardData() {
        var newObj = {};
        if (ref.current)
            ref.current.continuousStart()
        try {
            var res = await axios.get(BASE_URL + '/partners', {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                }
            });
            console.log("API RES:", res)
            if (res.status == 200 && res.data.partners) {

                res.data.partners = res.data.partners.map(val => {
                    var obj = { ...val };
                    // obj.contactPerson = val.phoneNumber;
                    // obj.email = val.contactPerson;
                    // obj.phoneNumber = val.email;
                    obj.phone = obj.phoneNumber
                    val = obj
                    return val
                });
                newObj["all-partners"] = res.data.partners.length;
                setPartnersList(res.data.partners);
                //setErr(false)
            }

            var res = await axios.get(BASE_URL + '/invoices?status=', {
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*"
                }
            });
            console.log("API RES:", res)
            if (res.status == 200 && res.data.invoices) {


               
                newObj["all-invoices"] = res.data.invoices.length;
                newObj["all-pending-invoices"] = res.data.invoices.filter(val => val.status == "PENDING").length;
                newObj["all-accepted-invoices"] = res.data.invoices.filter(val => val.status == "ACCEPTED").length;
                newObj["all-completed-invoices"] = res.data.invoices.filter(val => val.status == "COMPLETED").length;

                var newArr = [];
                newArr.push({ status: "All Invoices", invCount: res.data.invoices.length })
                newArr.push({ status: "Pending Invoices", invCount: res.data.invoices.filter(val => val.status == "PENDING").length })
                newArr.push({ status: "Accepted Invoices", invCount: res.data.invoices.filter(val => val.status == "ACCEPTED").length })
                newArr.push({ status: "Completed Invoices", invCount: res.data.invoices.filter(val => val.status == "COMPLETED").length })

                var yrObj = {}, yrArr = [];
                res.data.invoices.map(val => {
                    var yr = new Date(val.invoiceDate).getFullYear()
                    if (yrObj[yr] == undefined) {
                        yrArr.push({ value: "" + yr, label: "" + yr });
                        yrObj[yr] = true;
                    }
                })
                console.log(yrArr)

                setYears(yrArr)



                var dates = getDates(new Date().getFullYear());
                newObj["all-completed-invoices-mnth"] = res.data.invoices.filter(val => (
                    val.status == "COMPLETED" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)
                )).length

                newObj["all-pending-invoices-mnth"] = res.data.invoices.filter(val => (val.status == "PENDING" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld))).length

                console.log(res.data.invoices)

                setInvoiceList(res.data.invoices);
                setGrpData([...newArr])
                setOverviewData({ ...newObj })
                //setErr(false)

            }


        }
        catch (e) {
            setErr(true);
            logout();

            localStorage.setItem("loginDetails", null)
            window.location.reload()

        }
        if (ref.current)
            ref.current.complete()
    }


    useEffect(() => {
        if (err)
            setErr(!err)
        getDashboardData();
    }, [retry])





    // useEffect(() => {



    // }, [])


    const columns = useMemo(
        () => [
            {
                Header: "Invoice No",
                accessor: "invoiceNumber"
            },
            {
                Header: "Date",
                accessor: "invoiceDate",

            },
            {
                Header: "Amount",
                accessor: "amount"
            },
            {
                Header: "Total",
                accessor: "totalAmount"
            },
            {
                Header: "Status",
                accessor: "status"
            },
            //   {
            //     Header: "BTW Number",
            //     accessor: "btwNumber"
            //   },


        ]
        ,
        []
    );



    useEffect(() => {
        if (!curMonth || !curYear)
            return;
        
            // alert("")
            var dates = getDates(curYear,curMonth);

            var newObj={...overviewData}
        newObj["all-invoices"] = invoiceList.length;
        newObj["all-pending-invoices"] = invoiceList.filter(val => val.status == "PENDING" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)).length;
        newObj["all-accepted-invoices"] = invoiceList.filter(val => val.status == "ACCEPTED" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)).length;
        newObj["all-completed-invoices"] = invoiceList.filter(val => val.status == "COMPLETED" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)).length;

        var newArr = [];
        newArr.push({ status: "All Invoices", invCount: invoiceList.length })
        newArr.push({ status: "Pending Invoices", invCount: invoiceList.filter(val => val.status == "PENDING" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)).length })
        newArr.push({ status: "Accepted Invoices", invCount: invoiceList.filter(val => val.status == "ACCEPTED" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)).length })
        newArr.push({ status: "Completed Invoices", invCount: invoiceList.filter(val => val.status == "COMPLETED" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)).length })



 

       


        newObj["all-completed-invoices-mnth"] = invoiceList.filter(val => (
            val.status == "COMPLETED" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld)
        )).length

        newObj["all-pending-invoices-mnth"] = invoiceList.filter(val => (val.status == "PENDING" && new Date(val.invoiceDate) >= new Date(dates.fd) && new Date(val.invoiceDate) <= new Date(dates.ld))).length

        
        setGrpData([...newArr])
        setOverviewData({...newObj })

    }, [curMonth, curYear])






    return (
        <div>
            <RouteLabel />

            <div className='create-partner-form-main'>

                <LoadingBar color='#3072bb' ref={ref} height={7} />

                {partnersList.length > 0 && invoiceList.length > 0 ? <div>
                    <div className='dashboard-cont'>

                        <div className='row'>
                            <div className='card-overview bl-2' id="partners">
                                <div>
                                    <h3>Total partners</h3>
                                    <h3 className='detail'> {overviewData["all-partners"]} <span>Partners</span> </h3>
                                </div>
                                <div className='col2'>
                                    <i className="bi bi-people-fill bg1"></i>
                                </div>
                            </div>
                            <div className='card-overview bl-1' id="tinvoices">
                                <div>
                                    <h3>Total invoices</h3>
                                    <h3 className='detail'>{overviewData["all-invoices"]} <span>Invoices</span> </h3>
                                </div>
                                <div className='col2'>
                                    <i className="fas fa-file-invoice bg2" style={{ fontSize: "30px" }}></i>
                                </div>
                            </div>
                            <div className='card-overview bl-3' id="ainvoices">
                                <div>
                                    <h3>Total accepted invoices</h3>
                                    <h3 className='detail'>{overviewData["all-accepted-invoices"]} <span>Invoices</span></h3>
                                </div>
                                <div className='col2'>
                                    <i className="bi bi-file-earmark-check-fill bg3"></i>
                                </div>
                            </div>



                        </div>
                        <div className='dashboard-top-row2'>
                        <div>
                        <div className='dashboard-filter-sect'>
                            <p className='filtr' style={{ width: "fit-content", marginBottom: "6px", marginRight: "17px", fontSize: "19px" }}><b>Filters:</b></p>
                            <div className='filtr'>
                                <span>Select Year:</span>
                                <Select id="yr-drpdwn" options={years} onChange={(opt) => setCurYear(opt.value)} />
                            </div>

                            <div className='filtr'>
                                <span>Select Month:</span>
                                <Select id="mnth-drpdwn" options={monthLabels} onChange={(opt) => setCurMonth(opt.value)} />
                            </div>


                        </div>
                        <div className='card-overview desktop-overview' style={{display:"none"}} id="cinvoices">
                                            <div>
                                                <h3>Total Completed invoices</h3>
                                                <h3 className='detail'>{overviewData["all-completed-invoices"]} <span>Invoices</span></h3>
                                            </div>
                                            <div className='col2'>
                                                <i className="bi bi-check-square-fill bg1" style={{ fontSize: "30px", scale: "1" }}></i>
                                            </div>
                        </div>

                           <div className='chart-box resp-box center-graph' style={{width:"100%"}}>
                                        <LineChart chartData={ChartLayoutComp(grpData,'#ff6530',3)}  />
                           </div>
                        </div>
                        <div className='row2l bottom-overview'>
                                        <div className='card-overview' id="pinvoices">
                                            <div>
                                                <h3>Total pending invoices</h3>
                                                <h3 className='detail'>{overviewData["all-pending-invoices"]} <span>Invoices</span></h3>
                                            </div>
                                            <div className='col2'>
                                                <i className="fas fa-file bg4" style={{ fontSize: "30px", scale: "1" }}></i>
                                            </div>
                                        </div>


                                        <div className='card-overview mobile-overview2 desktop-overview2' id="cinvoices2">
                                            <div>
                                                <h3>Total Completed invoices</h3>
                                                <h3 className='detail'>{overviewData["all-completed-invoices"]} <span>Invoices</span></h3>
                                            </div>
                                            <div className='col2'>
                                                <i className="bi bi-check-square-fill bg1" style={{ fontSize: "30px", scale: "1" }}></i>
                                            </div>
                                        </div>


                                    {/* <div className='card-overview bl-4 mobile-overview' style={{ marginTop: "39px", flex: "0.25" }}>
                                        <div>
                                            <h3>Total Completed invoices</h3>
                                            <h3 className='detail'>{overviewData["all-pending-invoices"]} <span>Invoices</span></h3>
                                        </div>
                                        <div className='col2'>
                                            <i className="bi bi-check-square-fill bg1" style={{ fontSize: "30px", scale: "1.8" }}></i>
                                        </div><br />
                                        <div>
                                            <h3 className='detail'>{overviewData["all-completed-invoices-mnth"]} <span>Invoices in past month</span></h3>

                                        </div>
                                    </div> */}
                                </div>
                                </div>
                        {/* <div className='dashboard-filter-sect'>
                            <p className='filtr' style={{ width: "fit-content", marginBottom: "6px", marginRight: "17px", fontSize: "19px" }}><b>Filters:</b></p>
                            <div className='filtr'>
                                <span>Select Year:</span>
                                <Select id="yr-drpdwn" options={years} onChange={(opt) => setCurYear(opt.value)} />
                            </div>

                            <div className='filtr'>
                                <span>Select Month:</span>
                                <Select id="mnth-drpdwn" options={monthLabels} onChange={(opt) => setCurMonth(opt.value)} />
                            </div>


                        </div> */}
                        <div className='row2-parent'>

                            <div>
                                {/* <div className='row2' style={{ marginTop: "31px" }}> */}
                                    <div className='chart-box resp-box'>
                                        <BarChart chartData={ChartLayoutComp(grpData)} />
                                    </div>
                                    {/* <div className='chart-box resp-box'>
                                        <LineChart chartData={ChartLayoutComp(grpData)} />
                                    </div> */}



                                {/* </div> */}
                                {/* <div className='row2l'>
                                    <div className='card-overview2 bl-4 ' style={{ marginTop: "39px", flex: "0.30" }}>
                                        <div className='card-overview-sub' id="pinvoices">
                                            <div>
                                                <h3>Total pending invoices</h3>
                                                <h3 className='detail'>{overviewData["all-pending-invoices"]} <span>Invoices</span></h3>
                                            </div>
                                            <div className='col2'>
                                                <i className="fas fa-file bg4" style={{ fontSize: "30px", scale: "1.8" }}></i>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", alignItems: "center", marginTop: "-7%", position: "relative", zIndex: "1" }}>
                                            <div style={{ width: 119, height: 200, marginLeft: "14px" }}>

                                                <CircularProgressbar value={overviewData["all-pending-invoices-mnth"]==0?overviewData["all-pending-invoices-mnth"]:(overviewData["all-pending-invoices-mnth"] / overviewData["all-pending-invoices"]) * 100} text={`${overviewData["all-pending-invoices-mnth"]==0?overviewData["all-pending-invoices-mnth"]:(overviewData["all-pending-invoices-mnth"] / overviewData["all-pending-invoices"]) * 100}%`} />
                                            </div>
                                            <h3 className='detail'>{overviewData["all-pending-invoices-mnth"]} <span>Invoices                                         <br />
                                            <span className='sub-per-lbl'>in {monthLabels[parseInt(curMonth)-1].label} {curYear}</span> </span></h3>

                                        </div>
                                    </div>

                                    <div className='card-overview2 bl-4 ' style={{ marginTop: "39px", flex: "0.30" }}>
                                        <div className='card-overview-sub' id="cinvoices">
                                            <div>
                                                <h3>Total Completed invoices</h3>
                                                <h3 className='detail'>{overviewData["all-completed-invoices"]} <span>Invoices</span></h3>
                                            </div>
                                            <div className='col2'>
                                                <i className="bi bi-check-square-fill bg1" style={{ fontSize: "30px", scale: "1.8" }}></i>
                                            </div>
                                        </div>
                                        <br />
                                        <div style={{ display: "flex", alignItems: "center", marginTop: "-7%", position: "relative", zIndex: "1" }}>
                                            <div style={{ width: 119, height: 200, marginLeft: "14px" }}>
                                                <CircularProgressbar value={overviewData["all-completed-invoices-mnth"]==0?overviewData["all-completed-invoices-mnth"]:(overviewData["all-completed-invoices-mnth"] / overviewData["all-completed-invoices"]) * 100} text={`${overviewData["all-completed-invoices-mnth"]==0?overviewData["all-completed-invoices-mnth"]:(overviewData["all-completed-invoices-mnth"] / overviewData["all-completed-invoices"]) * 100}%`} />
                                            </div>
                                            <h3 className='detail'>{overviewData["all-completed-invoices-mnth"]} <span>Invoices                                         <br />
                                                <span className='sub-per-lbl'>in {monthLabels[parseInt(curMonth)-1].label} {curYear}</span> </span></h3>

                                        </div>
                                    </div>

                                    {/* <div className='card-overview bl-4' style={{ marginTop: "39px", flex: "0.25" }}>
                                        <div>
                                            <h3>Total Completed invoices</h3>
                                            <h3 className='detail'>{overviewData["all-pending-invoices"]} <span>Invoices</span></h3>
                                        </div>
                                        <div className='col2'>
                                            <i className="bi bi-check-square-fill bg1" style={{ fontSize: "30px", scale: "1.8" }}></i>
                                        </div><br />
                                        <div>
                                            <h3 className='detail'>{overviewData["all-completed-invoices-mnth"]} <span>Invoices in past month</span></h3>

                                        </div>
                                    </div> *+/}
                                </div> */}
                            </div>
                            <div >
                                <div className='chart-box2'>
                                    <h3 className='heading-tbl'>Latest Invoices</h3>
                                    <Table style={{ minWidth: "200px" }} data={invoiceList.sort((a,b)=>new Date(b.invoiceDate) - new Date(a.invoiceDate))} columns={columns} title={"Latest invoices"} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div> : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : <LoadingComponent />}
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>
        </div>

    )
}

export default Dashboard
import React from 'react'
import { Search } from 'react-feather'
import { Input } from 'reactstrap'

const SearchBar = () => {
  return (
    <div className='search-comp-sect' style={{ display: "flex" }}>
      <Input placeholder='Search...' />
      <Search size={40}  />
    </div>
  )
}

export default SearchBar
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Tooltip } from 'reactstrap';
import RouteLabel from '../components/RouteLabel';
import Table from '../components/Table';
import axios from 'axios';
import { BASE_URL, logout } from '../utils';
import LoadingBar from 'react-top-loading-bar';
import { Plus } from 'react-feather';
import { Link, useNavigate } from 'react-router-dom';
import LoadingComponent from '../components/LoadingComponent';
import ErrorComponent from '../components/ErrorComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PartnersList = () => {

  const notifyDel = () => toast.success("Deleted Successfully");
  const notifyErr = () => toast.error("Failed to delete partner!");

  const ref = useRef(null);
  const navigate = useNavigate();
  const [partnersList, setPartnersList] = useState([]);
  const [err, setErr] = useState(false);
  const [retry, setRetry] = useState(false)


  async function getPartners() {
    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.get(BASE_URL + '/partners', {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*"
        }
      });
      console.log("API RES:", res)
      if (res.status == 200 && res.data.partners) {

        res.data.partners = res.data.partners.map(val => {
          var obj = { ...val };
          // obj.contactPerson = val.phoneNumber;
          // obj.email = val.contactPerson;
          // obj.phoneNumber = val.email;
          obj.phone = obj.phoneNumber
          val = obj
          return val
        });
        setPartnersList(res.data.partners);
        setErr(false)
      }
    }
    catch (e) {
      setErr(true);
      logout();
      
      localStorage.setItem("loginDetails",null)
      window.location.reload()

    }
    if (ref.current)
      ref.current.complete()
  }


  useEffect(() => {
    if (err)
      setErr(!err)
    getPartners();
  }, [retry])


  console.log(partnersList)
  const columns = useMemo(
    () => [

      // {
      //   Header: "P.No",
      //   accessor: "btwNumber"

      // },
      {
        Header: "Name",
        accessor: "name",

      },
      {
        Header: "Phone",
        accessor: "phoneNumber"
      },
      {
        Header: "Contact person",
        accessor: "contactPerson"
      },
      {
        Header: "Email",
        accessor: "email"
      },
      {
        Header: "BTW Number",
        accessor: "btwNumber"
      },
      //     {
      //       Header: "Action ",
      //       Cell: (row) => {

      //         return (
      //           <div style={{ display: "flex" }}>

      //             <Button className='tbl-action-btn' color="btn btn-primary" onClick={()=>{ navigate("/partners/edit-partner",{state:{userData:{...row.row.original}}});}}><i className="fas fa-edit" ></i></Button>
      //             <Button className='tbl-action-btn' color="btn btn-primary" alt="Create Purchase Order" onClick={()=>{ navigate("/partners/create-purchase-order",{state:{userData:{...row.row.original}}});}}><i className="fas fa-plus" ></i></Button>
      //             <Button className='tbl-action-btn' color="btn btn-primary" onClick={()=>{ /*navigate("/session-timed-out");*/}}><i className="fas fa-trash" ></i></Button>
      //           </div>
      //         );
      //       }
      //     }

      //   ]
      //   ,
      //   []
      // );

      {
        Header: "Action ",
        Cell: (row) => {


          return (
            <div className='tbl-action-col'>

              <RenderBtn id="edit" tooltip="Edit" className='tbl-action-btn' color="btn btn-primary" onClick={() => { navigate("/partners/edit-partner", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-edit" ></i></RenderBtn>
              <RenderBtn id="create" tooltip="Create Purchase Order" className='tbl-action-btn' color="btn btn-primary" alt="Create Purchase Order" onClick={() => { navigate("/partners/create-purchase-order", { state: { userData: { ...row.row.original } } }); }}><i className="fas fa-plus" ></i></RenderBtn>
              <RenderBtn id="delete" tooltip="Delete" className='tbl-action-btn' color="btn btn-primary" onClick={() => { deletePartner(row.row.original)/*navigate("/session-timed-out");*/ }}><i className="fas fa-trash" ></i></RenderBtn>

            </div>
          );
        }
      }

    ]
    ,
    []
  );


  async function deletePartner(partner) {
    if (ref.current)
      ref.current.continuousStart()
    try {
      var res = await axios.delete(BASE_URL + `/partners/${partner.partnerId}`)
      if (res.status == 200 || res.status==204) {
          notifyDel();
          getPartners();
      }
    }
    catch (e) {
        notifyErr();
    }
    if (ref.current)
      ref.current.complete()
  }

  function RenderBtn({ className, onClick, icon, tooltip, id, children }) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
      <>

        <Button id={id} className={className} color="btn btn-primary" onClick={onClick}>{children}</Button>
        <Tooltip
          isOpen={tooltipOpen}
          toggle={toggle}
          placement="right" target={id} /*toggle={this.toggle}*/>
          {tooltip}
        </Tooltip>
      </>
    )
  }


  return (
    <div>
      <LoadingBar color='#3072bb' ref={ref} height={7} />
      {partnersList.length > 0 ? <div>
        <RouteLabel />
        <h2 className='page-title'>All Partners <Link className='add-invoice-link' to='/partners/create-partner/' ><Button className='page-btn'  >Create Partner <Plus size={20} /></Button></Link></h2>
        <Table data={partnersList} columns={columns} title={"Partners List"} />
      </div> : err ? <ErrorComponent retry={retry} setRetry={setRetry} /> : <LoadingComponent />}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export { PartnersList }
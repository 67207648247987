import React, { useState } from 'react'
import { Search } from 'react-feather';
import { useAsyncDebounce } from 'react-table'
import { Input } from 'reactstrap';

export const GlobalFilter = ({ filter, setGlobalFilter }) => {

  const [filterInput, setFilterInput] = useState("");

  // Update the state when input changes
  const handleFilterChange = e => {
    const value = e.target.value || undefined;
    setFilterInput(value);
    setGlobalFilter(e.target.value)
  };


  return (
  

    <div className='search-comp-sect' >
      <Input placeholder='Search...' onChange={handleFilterChange} />
      <Search size={40} className='global-filter-search-btn'  />
    </div>
  )
}